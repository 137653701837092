export enum StorageKeys {
  ByggestromInvoiceProjectTypeId = 'byggestromInvoiceProjectTypeId',
  ByggestromInvoiceCaseworkerId = 'byggestromInvoiceCaseworkerId',
  SidebarOpen = 'sidebarOpen',
  CaseworkerFilter = 'caseworkerFilter',
  ProjectMapStatusGroup = 'projectMapStatusGroup',
  CurrentUser = 'currentUser',
  DepartmentIds = 'departmentIds',
  FavoriteDepartmentIds = 'favoriteDepartmentIds',
  RequestMapView = 'request-map/view',
  EquipmentOpt = 'equipment/opt',
  IssuesOpt = 'issues/opt',
  IssuesProcessing = 'issues/processing',
  ArchiveCoordinates = 'archive/coordinates',
  ArchiveListview = 'archive/listview',
}

type StorageKey = StorageKeys | string;

interface StorageOptions {
  /** If the key should follow the user, and not be avilable if logging in with other users */
  useUser?: boolean;

  /** If the value should be stored/read to/from session storage instead of local storage */
  useSession?: boolean;
}

export function getItem(
  key: StorageKey,
  { useUser = false, useSession = false }: StorageOptions = {},
) {
  return JSON.parse(
    (useSession ? sessionStorage : localStorage).getItem(
      useUser ? `${getItem(StorageKeys.CurrentUser)?.id}/${key}` : key,
    ),
  );
}

export function setItem(
  key: StorageKey,
  value,
  { useUser = false, useSession = false }: StorageOptions = {},
) {
  (useSession ? sessionStorage : localStorage).setItem(
    useUser ? `${getItem(StorageKeys.CurrentUser)?.id}/${key}` : key,
    JSON.stringify(value),
  );
}

export function removeItem(
  key: StorageKey,
  { useUser = false, useSession = false }: StorageOptions = {},
) {
  (useSession ? sessionStorage : localStorage).removeItem(
    useUser ? `${getItem(StorageKeys.CurrentUser)?.id}/${key}` : key,
  );
}
