import { ref } from 'vue';
import { useTenantStore } from '/@features/tenant';
import { loadValuelistByGuid } from '/@stores/valuelists';
import { useProjectStore } from '/@features/project';
import { ValidationRule } from '/@tools/form';
import { api, genericApiErrorHandler } from '/@tools/api';
import { Project } from '/@features/project/project.types';
import { Geometry } from '/@shared/types/geometry';
import { Property } from '/@shared/types/properties';
import { sortIndex } from '../tools/general-utils';

export type Form = {
  id: number;
  name: string;
  categories: FormCategory[];
  fields: FormField[];
};

type FormCategory = {
  formId: number;
  id: number;
  name: string;
  sortIndex: number;
  hiddenByDefault: boolean;
  typeFeatures: string[];
  collapsedByDefault: boolean;
};

export type FormField = {
  formId: number;
  categoryId: number;
  id: number;
  name: string;
  propertyId: string | number;
  propertyIdSource: 'Named' | 'Dynamic';
  sortIndex: number;
  valuelistGuid: string | null;
  fieldType: 'input' | 'select' | 'checkbox' | 'datetime';
  // dynamic prop has value/valueId obj
  getValuelist?: (
    dependencies: any[],
  ) => Map<any, { name: string } | { value: number | string | null; valueId: number | null }>;
  getValuelistFavorites?: (dependencies: any[]) => Map<any, { name: string }>;
  dependencies?: Array<() => Promise<any>>;
  _resolvedDependencies?: any[];
  show?: () => boolean;
  validation?: ({ form }: { form: any }) => ValidationRule[];
  multiline?: boolean;
  disabled?: (data: SupportData) => boolean;
  hasQRScanner: boolean;
};
// used by disabled to evalutate condition. could be used by validation in the future
export type SupportData = {
  isEdit: boolean;
  isIntegration: boolean;
};

function mapForm(formData: any): Form {
  return {
    id: formData.Form.Id,
    name: formData.Form.Name,
    categories: formData.FormCategories.map((c) => mapFormCategory(c)),
    fields: formData.FormFields.map((f) => mapFormField(f)).toSorted((a, b) =>
      sortIndex(a.sortIndex, b.sortIndex),
    ),
  };
}

function mapFormCategory(cat: any): FormCategory {
  return {
    formId: cat.FormId,
    id: cat.Id,
    name: cat.Name,
    sortIndex: cat.SortIndex,
    hiddenByDefault: cat.HiddenByDefault,
    typeFeatures: cat.ApplicationFeatures,
    collapsedByDefault: cat.CollapsedByDefault,
  };
}

function mapFormField(field: any): FormField {
  return {
    formId: field.FormId,
    categoryId: field.FormCategoryId,
    id: field.Id,
    name: field.Name,
    propertyId: field.PropertyId || field.ProjectField,
    propertyIdSource: field.Context,
    sortIndex: field.RowIndex,
    valuelistGuid: field.ValueListGuid,
    fieldType: field.DataType || 'input',
    hasQRScanner: field.HasQRScanner,
    ...mapNamedProperty(field),
    ...mapDynamicProperty(field),
  };
}

function mapNamedProperty(field: any): Partial<FormField> | undefined {
  const tenantStore = useTenantStore();
  const projectStore = useProjectStore();

  switch (field.PropertyId || field.ProjectField) {
    case 'CaseworkerId':
      return {
        fieldType: 'select',
        getValuelist: ([caseworkers]) => caseworkers,
        dependencies: [tenantStore.loadCaseworkers],
        disabled: (data) => data.isEdit && data.isIntegration,
      };

    case 'Installer':
      return {
        fieldType: 'select',
        getValuelist: ([installers]) => new Map([...installers].map(([id, i]) => [i, i])),
        dependencies: [tenantStore.loadInstallers],
      };

    case 'DepartmentId':
      return {
        fieldType: 'select',
        getValuelist: ([departments]) => departments,
        dependencies: [tenantStore.loadDepartments],
        show: () => tenantStore.departments.size > 0,
        validation: ({ form }) => [
          [tenantStore.departments.size > 0 && !form.DepartmentId, 'Avdeling kreves'],
        ],
        disabled: (data) => data.isEdit && data.isIntegration,
      };

    case 'FolderId':
      return {
        fieldType: 'select',
        getValuelist: ([folders]) => new Map([...folders].map(([id, { name }]) => [id, name])),
        getValuelistFavorites: ([folders]) =>
          new Map([...folders].filter(([id, { isFavorite }]) => isFavorite)),
        dependencies: [projectStore.loadProjectFolders],
        disabled: (data) => data.isEdit && data.isIntegration,
      };

    case 'Description':
      return {
        multiline: true,
      };

    case 'OverviewListEnabled':
      return { fieldType: 'checkbox' };

    case 'DueDate':
      return {
        fieldType: 'datetime',
      };

    case 'ContactId':
      return { show: () => false };

    case 'Name':
      return {
        validation: ({ form }) => {
          return [
            [
              !form.Name?.trim(),
              'Prosjekt kreves. Fyll inn adresse, kontrakts-/ordrenr. eller internnr.',
            ],
          ];
        },
      };

    case 'ReferenceNo2':
      return {
        disabled: (data) => data.isEdit && data.isIntegration,
      };
  }
}

function mapDynamicProperty(field: any): Partial<FormField> | undefined {
  if (field.Context !== 'Dynamic') return;
  return {
    ...(field.ValueListGuid && {
      getValuelist: ([list]) => list,
      dependencies: [() => loadValuelist(field.ValueListGuid)],
    }),
  };
}

async function loadValuelist(valuelistGuid: string) {
  return new Map(
    [...(await loadValuelistByGuid({ valuelistGuid })).valuelistItems.values()].map((i) => [
      i.id,
      i,
    ]),
  );
}

export type ProjectForm = {
  ProjectTypeId: number | null;
  Name?: string | null;
  Description?: string | null;
  DepartmentId?: number | null;
  ReferenceNo1?: string | null;
  ReferenceNo2?: string | null;
  FacilityId?: string | null;
  FolderId?: number | null;
  ColorId?: number | null;
  Installer?: string | null;
  CaseworkerId?: number | null;
  DueDate?: Date | null;
  Municipality?: string | null;
  Address?: string | null;
  Geometry?: Geometry | null;
  Longitude?: number | null;
  Latitude?: number | null;
  GridownerId?: number | null;
  ContactCompanyId?: number | null;
  ContactId?: number | null;
  GridownerProjectName?: string | null;
  OverviewListEnabled?: boolean | null;
  EnableProjectShare?: boolean | null;
  PriorityId?: number | null;
};

function initForm(form: Form) {
  const loadMapper = form.fields.map(async (field) => {
    if (!field.dependencies) return;
    field._resolvedDependencies = await Promise.all(field.dependencies.map((fn) => fn()));
  });

  return Promise.all(loadMapper).then(() => form);
}

const formTemplate = ref<Form | null>(null);
const formData = ref<ProjectForm>({ ProjectTypeId: null });

export function useForm() {
  function loadFormTemplate(
    typeId: number,
    { initialize } = { initialize: true },
  ): Promise<Form | null> {
    formTemplate.value = null;

    return api
      .get(`projecttypes/${typeId}/form`)
      .then(async ({ data }) => {
        if (!data) return;
        formTemplate.value = await (initialize ? initForm(mapForm(data)) : mapForm(data));
      })
      .catch(genericApiErrorHandler);
  }

  type PopulateFormData = { typeId: number; project?: Project; properties?: Map<number, Property> };

  function populateFormData({ typeId, project, properties }: PopulateFormData) {
    formData.value = {
      ProjectTypeId: typeId,
      ...mapFormDefaults(),
      ...(!!(project && properties) && mapProjectToForm(project, properties)),
    };
  }

  function mapFormDefaults() {
    if (!formTemplate.value) return;

    return {
      ...Object.fromEntries(
        formTemplate.value.fields.map((f) => [
          f.propertyId,
          f.propertyIdSource === 'Dynamic'
            ? { value: null, valueId: null }
            : f.fieldType === 'checkbox'
              ? false
              : null,
        ]),
      ),
      ...(formTemplate.value.fields.some((f) => f.propertyId === 'Address') && {
        Geometry: null,
        Address: null,
        Municipality: null,
        Latitude: null,
        Longitude: null,
      }),
    };
  }

  function mapProjectToForm(p: Project, properties: Map<number, Property>): ProjectForm {
    return {
      ProjectTypeId: p.type,
      Name: p.name,
      Description: p.description,
      DepartmentId: p.departmentId,
      ReferenceNo1: p.ref1,
      ReferenceNo2: p.ref2,
      FacilityId: p.facilityId,
      FolderId: p.folderId !== 0 ? p.folderId : null,
      ColorId: p.colorId,
      Installer: p.installer,
      CaseworkerId: p.caseworkerId,
      DueDate: p.dueDate,
      Municipality: p.municipality,
      Address: p.address,
      Geometry: p.geometry,
      Longitude: p.longitude,
      Latitude: p.latitude,
      GridownerId: p.gridownerId,
      ContactCompanyId: p.contactCompanyId,
      ContactId: p.contactId,
      GridownerProjectName: p.gridownerProjectName,
      OverviewListEnabled: p.overviewListEnabled,
      PriorityId: p.priorityId,
      ...Object.fromEntries(
        [...properties].map(([id, prop]) => [id, { value: prop.value, valueId: prop.valueId }]),
      ),
    };
  }

  function separateFormProperties() {
    const named = [];
    const dynamic = [];

    for (const [fieldId, value] of Object.entries(formData.value)) {
      const field = formTemplate.value?.fields.find((f) => String(f.propertyId) === fieldId);

      field?.propertyIdSource === 'Dynamic'
        ? dynamic.push([fieldId, value])
        : named.push([fieldId, value]);
    }

    return { named, dynamic: dynamic.map((p) => [p[0], p[1]]) };
  }

  return { formTemplate, loadFormTemplate, formData, populateFormData, separateFormProperties };
}
