import { storeToRefs } from 'pinia';
import { mapProjectType } from './project.mappers';
import { loadProjectFolders, loadProjectStatuses, useProjectStore } from './project.store';
import { addDepartmentQueries } from '/@molecules/department-button/helpers';
import { useContacts } from '/@composables';
import { awaitUser2 } from '/@features/user';
import { filterQuery, api, createQueryParams, genericApiErrorHandler } from '/@tools/api';
import { convertMunicipality, hasRolePerm } from '/@tools/general-utils';
import { date, localISODate } from '/@tools/intl';
import { filterListCountMapper, getFilterValue } from '/@tools/list';
import { StorageKeys, getItem } from '/@tools/storage';
import { useTenantStore } from '/@features/tenant/tenant.store';
import { loadFavoriteProjectFolders, useUserStore } from '/@features/user/user.store';
import { loadValuelistByGuid } from '/@stores/valuelists';
import { setTime } from '/@shared/tools/date-utils';
import { priorityOptions } from '/@features/request/request.tools';
import { log } from '/@plugins/log';
import { useColorStore } from '/@stores/colors';

import { Geometry } from '/@types/geometry';
import { ProjectType } from './project.types';
import { Opt, OptColumn, OptType } from '/@types/list';
import { sendError } from '/@plugins/sentry';
import { useAdminStore } from '../admin';
import { StatusGroups } from '/@types/ids';
import { RolePerm } from '/@features/user/user.types';
// import { DynamicProjectProperty } from './project.types';

type Id = number;

export type Project2 = {
  id: number;
  name: string;
  description: string;
  typeId: number;
  statusId: number;
  geometry: Geometry;
  address: string;
  municipality: string;
  // postalPlace: string, // Legge til?
  // postalNumber: string, // Legge til?
  // priorities: { request: number }, // Fjerne?

  ref1: string; // -> dynamisk prop ("Kontrakts- / ordrenummer" (ref1))
  customId: string; // ("Intern nr" (ref2))
  installer: string | null; //-> dynamisk prop? ("Andsvarlig montør")
  facilityId: string; // -> dynamisk prop
  // customer: string, // -> dynamisk prop

  caseworkerName: string | null;
  caseworkerId: number | null;
  caseworkerEmail: string | null;

  folderId: number;
  // folderName: string // Fjerne? (må benytte GET /projectFolders (vil ikke være tilgjengelig for partner))

  tenantId: number;
  // tenantName: string, // Fjerne? (må benytte GET /partners)

  createdAt: Date;
  updatedAt: Date;

  typeProps: Array<[Id, string | null]>;
  typeProps2: Array<[Id, { valueId: string | null; value: string | null }]>;
  typeLabels: Array<[Id, string | null]>;

  overviewListEnabled: boolean;
  sharedWithTenants: Array<{ name: string; id: number }>;
  sharedPhotoreportsWithTenants: Array<{ name: string; id: number }>;

  gridownerProjectName: string;
  gridownerAddress: string;
  departmentId: number | null;

  contactId: number | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  contactCompanyId: number | null;
  contactCompanyName: string | null;

  customer: string | null;
  longitude: number | null;
  latitude: number | null;
  colorId: number | null;

  priorityId: number | null;
  dueDate: Date | null;

  requestComment: string | null;
  requestDescription: string | null;
  requestPriorityId: string | null;
  requestDueDate: Date | null;
};

type LoadProjectPageOptions = {
  moduleId: number;
  opt: Opt;
  columns: OptColumn[];
  incPropIds?: number[];
  incLabelIds?: number[];
  ids?: number[];
  signal?: AbortSignal | null;
  useDepartments?: boolean;
  // loads request props
  useRequests?: boolean;

  /** Toggle between projects owned by tenant or shared to tenant */
  shared?: boolean;
};

export enum ProjectOptColumn {
  Id = 'Id',
  Name = 'Name',
  TypeId = 'TypeId',
  Gridowner = 'Gridowner',
  StatusGroupId = 'StatusGroupId',
  StatusId = 'StatusId',
  CustomId = 'CustomId',
  OrderId = 'OrderId',
  FacilityId = 'FacilityId',
  Address = 'Address',
  Municipality = 'Municipality',
  FolderId = 'FolderId',
  Installer = 'Installer',
  CaseworkerId = 'CaseworkerId',
  CreatedAt = 'CreatedAt',
  UpdatedAt = 'UpdatedAt',
  TenantId = 'TenantId',
  SharedPhotoreport = 'SharedPhotoreport',
  Shared = 'Shared',
  ContactCompany = 'ContactCompany',
  Contact = 'Contact',
  GridownerProjectname = 'GridownerProjectname',
  Position = 'Position',
  DepartmentId = 'DepartmentId',

  PriorityId = 'PriorityId',
  DueDate = 'DueDate',
  RequestDueDate = 'RequestDueDate',
  RequestPriorityId = 'RequestPriorityId',
  RequestComment = 'RequestComment',
  RequestDescription = 'RequestDescription',
}

function createProjectQueryParams({
  opt,
  columns,
  moduleId,
  shared,
  incPropIds = [],
  incLabelIds = [],
  ids = [],
  useDepartments,
  useRequests,
}) {
  let query = filterQuery(opt, columns);

  query.append('moduleId', String(moduleId));
  query.append('sharedProjects', String(shared));

  incPropIds.forEach((id) => {
    query.append('incPropIds', String(id));
  });

  incLabelIds.forEach((id) => {
    query.append('incLabelIds', String(id));
  });

  ids.forEach((id) => {
    query.append('ids', String(id));
  });

  if (!opt.filters.some(({ columnId }) => columnId === 'tenantIds') && useDepartments) {
    query = addDepartmentQueries(query);
  }

  if (!query.has('statusgroupid')) {
    query.append('statusgroupid', String(1));
  }

  if (useRequests) {
    query.append('includeRequests', 'true');
  }

  return query;
}

export function mapProject(res): Project2 {
  return {
    id: res.Id,
    name: res.Name,
    description: res.Description,
    typeId: res.TypeId,
    statusId: res.StatusId,
    geometry: res.Geometry,
    address: res.Address,
    municipality: res.Municipality,
    ref1: res.ReferenceNo1,
    customId: res.ReferenceNo2,
    facilityId: res.FacilityId,
    caseworkerId: res.CaseworkerId,
    caseworkerName: res.CaseworkerName,
    caseworkerEmail: res.CaseworkerEmail,
    installer: res.Installer,
    folderId: res.FolderId,
    tenantId: res.TenantId,
    createdAt: res.CreatedAt,
    updatedAt: res.UpdatedAt,
    dueDate: typeof res.DueDate === 'string' && res.DueDate.startsWith('0001') ? null : res.DueDate,
    overviewListEnabled: res.OverviewListEnabled,
    sharedWithTenants: res.SharedWithTenants.map((t) => ({ name: t.Name, id: t.Id })),
    sharedPhotoreportsWithTenants: res.SharedPhotoReportsWithTenants.map((t) => ({
      name: t.Name,
      id: t.Id,
    })),
    gridownerProjectName: res.GridownerProjectName,
    gridownerAddress: res.GridownerAddress,
    typeProps: res.ProjectTypeProperties.map(({ Id, Value }) => [Id, Value]),
    typeProps2: res.ProjectTypeProperties.map(({ Id, Value, ValueId }) => [
      Id,
      { valueId: ValueId, value: Value },
    ]),
    typeLabels: res.ProjectLabels.map(({ LabelKeyId, Name }) => [LabelKeyId, Name]),
    departmentId: res.DepartmentId,
    contactId: res.ContactId,
    contactName: res.ContactName,
    contactEmail: res.ContactEmail,
    contactPhone: res.ContactPhone,
    contactCompanyId: res.ContactCompanyId,
    contactCompanyName: res.ContactCompanyName,

    customer: res.Customer,
    longitude: res.Longitude,
    latitude: res.Latitude,
    colorId: res.ColorId,
    priorityId: res.PriorityId,

    requestDescription: res.RequestDescription,
    requestComment: res.RequestComment,
    requestDueDate: res.RequestDueDate ?? new Date(res.RequestDueDate),
    requestPriorityId: res.RequestPriorityId,
  };
}

export function loadValuelistCountByPropName({
  moduleId,
  propName,
  opt,
  columns,
  shared = false,
}): Promise<Map<string, number>> {
  const query = createProjectQueryParams({
    opt,
    columns,
    moduleId,
    shared,
    useDepartments: true,
    useRequests: true,
  });

  return api
    .get(`/v2/projects/propertyvaluecount/${propName}?moduleId=${moduleId}&${query.toString()}`)
    .then(({ data }) => {
      return new Map(
        data.map(({ Value, ValueCount }) => [Value, ValueCount]) as [string, number][],
      );
    })
    .catch((error) => {
      log.error(
        error,
        'Couldnt count property {Property} with error {Error}',
        propName,
        error?.message,
      );
      console.error(error);
      sendError('Couldnt count property', { error, propName });
      return new Map();
    });
}

export function loadValuelistCountByPropId({ moduleId, propId, opt, columns, shared }) {
  const query = createProjectQueryParams({ opt, columns, moduleId, shared });

  return api
    .get(`/v2/projects/propertyvaluecount/${propId}?moduleId=${moduleId}&${query.toString()}`)
    .then(({ data }) => {
      return new Map(data.map(({ Value, ValueCount }) => [Value, ValueCount]));
    });
}

export function loadProjectTypes(
  moduleId: number,
  tenantId: number,
): Promise<Map<number, ProjectType>> {
  const queries = createQueryParams(new Map([['tenantIds', tenantId]]));
  return api
    .get(`/modules/${moduleId}/projecttypes?${queries}`)
    .then(
      ({ data }: { data: Array<any> }) =>
        new Map(data.map((type) => [type.Id, mapProjectType(type, moduleId, tenantId)])),
    )
    .catch(genericApiErrorHandler);
}

export async function getProjectOptColumns({
  moduleId,
  tenantId,
  statusGroupId,
  shared = false,
  isIntegration = false,
  context = null,
  selectedTypeId = null,
}: {
  moduleId: number;
  tenantId: number;
  statusGroupId: number;
  shared: boolean;
  isIntegration: boolean;
  context: 'partner' | 'kommunepartner' | 'request' | null;
  selectedTypeId: number | null;
}): Promise<OptColumn[]> {
  const countFn = (args: any) => loadValuelistCountByPropName({ ...args, moduleId, shared });
  const countIdFn = (args: any) => loadValuelistCountByPropId({ ...args, moduleId, shared });

  const tenantStore = useTenantStore();
  const departments = await tenantStore.loadDepartments({});
  const partners = await tenantStore.loadPartners();
  const types = await loadProjectTypes(moduleId, tenantId);
  const { loadColors } = useColorStore();

  const projectStore = useProjectStore();
  const { tenantProjectProperties } = storeToRefs(projectStore);
  const adminStore = useAdminStore();
  const { user } = storeToRefs(useUserStore());

  await awaitUser2;
  await projectStore.loadTenantProjectProperties(tenantId);

  const partnerColumns = [
    ProjectOptColumn.Name,
    ProjectOptColumn.Municipality,
    ProjectOptColumn.StatusGroupId,
    ProjectOptColumn.StatusId,
    ProjectOptColumn.FolderId,
    ProjectOptColumn.TypeId,
  ];

  const kpColumns = [
    ProjectOptColumn.StatusGroupId,
    ProjectOptColumn.StatusId,
    ProjectOptColumn.ContactCompany,
  ];

  let columns = [
    {
      id: ProjectOptColumn.Name,
      name: 'Prosjektnavn',
      sortKey: 'name',
      filterKey: 'projectname',
      startOpen: true,
      width: 24,
    },
    {
      id: ProjectOptColumn.Id,
      name: 'Prosjekt-ID',
      sortKey: 'id',
      filterKey: 'ids',
    },
    {
      id: ProjectOptColumn.TypeId,
      name: 'Prosjekttype',
      sortKey: 'typeid',
      filterKey: 'typeid',
      filterListOnly: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }, [types]) => [
        Number(value),
        { name: types.get(Number(value))?.name ?? value, count },
      ]),
      mapValues: (value, [types]) => types.get(value)?.name,
      dependencies: [types],
      startOpen: true,
    },
    {
      id: ProjectOptColumn.StatusGroupId,
      name: 'Statusgruppe',
      filterKey: 'statusgroupid',
      isHidden: () => true,
    },
    {
      id: ProjectOptColumn.DepartmentId,
      name: 'Avdeling',
      sortKey: 'departmentid',
      // not ready from backend
      // filterKey: 'department',
      // getFilterList: filterListCountMapper(
      //   countFn,
      //   ({ value, count }, [departments]) => [Number(value), { name: departments.get(Number(value)) ?? value, count }],
      // ),
      mapValues: (value, [departments]) => departments.get(value),
      dependencies: [departments],
    },
    {
      id: ProjectOptColumn.StatusId,
      name: 'Status',
      sortKey: 'statusid',
      filterKey: 'statusids',
      filterListOnly: true,
      filterMultiple: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count, opt }, [statuses, colors]) => {
        const [selectedType] = getFilterValue(opt.filters, ProjectOptColumn.TypeId);
        const status = statuses.get(Number(value));

        // NOTE: Should we also respect `status.hiddenInProjectTypeIds` here? Could lead to problems where you try to see statuses of partner who doesn't have it hidden
        // If we use it we could check `opt.tenantId == null` to check if we're accessing our own or some other tenant
        // Ideally we don't bother filtering these out as it adds a lot of logic to just hide a few already semi-hidden/empty filters.
        if (!count && selectedType != null && !status?.projectTypeIds.includes(selectedType))
          return null;

        return [
          Number(value),
          {
            name: status?.name ?? value,
            count,
            color: colors.get(status?.colorId) ?? 'black',
            sortIndex: status?.sortIndex,
          },
        ];
      }),
      mapValues: (value, [statuses]) => statuses.get(value)?.name,
      getOptions: ([statuses], opt) => {
        const [typeId] = getFilterValue(opt.filters, ProjectOptColumn.TypeId);
        return new Map(
          [...statuses]
            .filter(([_, status]) => status.projectTypeIds.includes(typeId))
            .map(([id, { name, sortIndex }]) => [id, { name, sortIndex }]),
        );
      },
      dependencies: [() => loadProjectStatuses({ moduleIds: [moduleId] }), () => loadColors()],
      startOpen: true,
      editable:
        statusGroupId === StatusGroups.Avsluttede
          ? hasRolePerm(user.value, RolePerm.ProjectItemReopen)
          : true,
    },
    {
      id: ProjectOptColumn.FolderId,
      name: 'Prosjektmappe',
      sortKey: 'folder',
      filterKey: 'folderid',
      filterListOnly: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }, [folders, favorites]) => {
        const favDepartments = getItem(StorageKeys.FavoriteDepartmentIds, { useUser: true });
        const folder = folders.get(Number(value));
        if (folder?.disabled && count === 0) return null;
        return value === '0'
          ? [-100, { name: 'Ingen verdi', count }]
          : [
              Number(value),
              {
                name:
                  folder == null
                    ? value
                    : folder.disabled
                      ? `${folder.name} (Ikke aktiv)`
                      : folder.name,
                count,
                subtitle:
                  favDepartments?.length > 0 ? folders.get(Number(value))?.department : null,
                favorite: favorites.has(Number(value)),
              },
            ];
      }),
      mapValues: (value, [folders]) =>
        value === -100 ? 'Ingen verdi' : (folders.get(value)?.name ?? value),
      getOptions: ([folders, favorites]) =>
        new Map([
          [null, { name: 'Ingen verdi' }],
          ...[...folders]
            .filter(([_, { disabled }]) => !disabled)
            .map(([id, { name }]): [number, { name: string; favorite?: boolean }] => [
              id,
              { name, favorite: favorites.has(id) },
            ]),
        ]),
      dependencies: [
        () => loadProjectFolders({ includeDisabled: true }),
        () => loadFavoriteProjectFolders(),
        () => awaitUser2,
      ],
      editable: !isIntegration,
    },
    {
      id: ProjectOptColumn.Municipality,
      name: 'Kommune',
      category: 'Adresse',
      sortKey: 'municipality',
      filterKey: 'municipality',
      filterListOnly: true,
      filterMultiple: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }) =>
        value == null
          ? ['#EMPTY', { name: 'Ingen verdi', count }]
          : [value, { name: convertMunicipality(value), count }],
      ),
      mapValues: (value) => (value === '#EMPTY' ? 'Ingen verdi' : convertMunicipality(value)),
      editable: false,
    },
    {
      id: ProjectOptColumn.CaseworkerId,
      name: 'Saksbehandler',
      sortKey: 'caseworker',
      filterKey: 'caseworkerids',
      filterMultiple: true,
      filterListOnly: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }, [caseworkers]) => {
        const caseworker = caseworkers.get(Number(value));
        if (caseworker?.disabled && count === 0) return null;
        return value === '0'
          ? [-100, { name: 'Ingen verdi', count }]
          : [
              Number(value),
              {
                name:
                  caseworker == null
                    ? value
                    : caseworker.disabled
                      ? `${caseworker.name} (Ikke aktiv)`
                      : caseworker.name,
                count,
              },
            ];
      }),
      mapValues: (value, [caseworkers]) =>
        value === -100 ? 'Ingen verdi' : (caseworkers.get(value)?.name ?? value),
      getOptions: ([caseworkers]) =>
        new Map([
          [null, { name: 'Ingen verdi' }],
          ...[...caseworkers]
            .filter(([_, { disabled }]) => !disabled)
            .map(([id, { name }]): [number, { name: string }] => [id, { name }]),
        ]),
      dependencies: [
        () =>
          tenantStore.loadCaseworkers2({ moduleId, statusGroupId, shared, includeDisabled: true }),
      ],
      editable: !isIntegration,
    },
    {
      id: ProjectOptColumn.Installer,
      name: 'Ansvarlig montør',
      sortKey: 'installer',
      filterKey: 'installer',
      filterListOnly: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }) =>
        value ? [value, { name: value, count }] : ['#EMPTY', { name: 'Ingen verdi', count }],
      ),
      mapValues: (value) => (value === '#EMPTY' ? 'Ingen verdi' : String(value)),
      getOptions: ([installers]) =>
        new Map([
          [null, { name: 'Ingen verdi' }],
          ...[...installers].map(([_, name]): [number, { name: string }] => [name, { name }]),
        ]),
      // Installers is not ID based on projects, so we need to change id to textual value before using
      dependencies: [
        () =>
          tenantStore
            .loadInstallers()
            .then((installers) => new Map([...installers.values()].map((v) => [v, v]))),
      ],
      editable: true,
    },
    {
      id: ProjectOptColumn.ContactCompany,
      name: 'Oppdragsgiver/kunde',
      sortKey: 'contactcompanyname',
      filterKey: 'contactcompanyids',
      filterListOnly: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }, [contactCompanies]) => {
        const company = contactCompanies.get(Number(value));
        if (company?.deletedAt != null && count === 0) return null;
        return value === '0'
          ? [-100, { name: 'Ingen verdi', count }]
          : [
              Number(value),
              {
                name:
                  company == null
                    ? value
                    : company.deletedAt != null
                      ? `${company.name} (Ikke aktiv)`
                      : company.name,
                favorite: company?.favorite,
                count,
              },
            ];
      }),
      mapValues: (value, [contactCompanies]) =>
        value === -100 ? 'Ingen verdi' : (contactCompanies.get(Number(value))?.name ?? value),
      getOptions: ([contactCompanies]) =>
        new Map([
          [null, { name: 'Ingen verdi' }],
          ...[...contactCompanies].map(
            ([id, { name, favorite }]): [number, { name: string; favorite?: boolean }] => [
              id,
              { name, favorite },
            ],
          ),
        ]),
      dependencies: [() => useContacts().loadContactCompanies({ includeDeleted: true })],
      editable: true,
    },
    {
      id: ProjectOptColumn.Contact,
      name: 'Kontaktperson',
      sortKey: 'contactname',
      filterKey: 'contactids',
      filterListOnly: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }, [contacts]) => {
        const contact = contacts.get(Number(value));
        if (contact?.deletedAt != null && count === 0) return null;
        return value === '0'
          ? [-100, { name: 'Ingen verdi', count }]
          : [
              Number(value),
              {
                name:
                  contact == null
                    ? value
                    : contact.deletedAt != null
                      ? `${contact.name} (Ikke aktiv)`
                      : contact.name,
                count,
              },
            ];
      }),
      mapValues: (value, [contacts]) =>
        value === -100 ? 'Ingen verdi' : (contacts.get(Number(value))?.name ?? value),
      getOptions: ([contacts]) =>
        new Map([
          [null, { name: 'Ingen verdi' }],
          ...[...contacts].map(([id, { name }]): [number, { name: string }] => [id, { name }]),
        ]),
      dependencies: [() => useContacts().loadContacts({ includeDeleted: true })],
    },
    {
      id: ProjectOptColumn.Shared,
      name: 'Tildelt',
      filterKey: 'sharedwithtenantids',
      // filterList: this.filterValueList,
      filterListOnly: true,
      filterMultiple: true,
      getFilterList: async ({ opt, column, columns }) => {
        const partners =
          typeof column.dependencies?.[0] === 'function'
            ? await column.dependencies[0]()
            : await column.dependencies?.[0];
        return new Map([
          [-100, { name: 'Ingen verdi', count: null }],
          ...[...partners].map(([id, { name }]) => [id, { name, count: null }]),
        ]);
      },
      dependencies: [partners],
      mapValues: (value, [partners]) =>
        value === -100 ? 'Ingen verdi' : (partners.get(value)?.name ?? value),
      width: 14,
    },
    {
      id: ProjectOptColumn.SharedPhotoreport,
      name: 'Delt bilderapport',
      width: 14,
    },
    {
      id: ProjectOptColumn.TenantId,
      name: 'Eier',
      width: 14,
      isHidden: () => !shared,
      filterKey: 'partnerId',
      filterListOnly: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }, [partners]) => {
        return [Number(value), { name: partners.get(Number(value))?.name ?? value, count }];
      }),
      mapValues: (value, [partners]) => partners.get(value)?.name ?? value,
      dependencies: [partners],
      startOpen: true,
    },
    {
      id: ProjectOptColumn.OrderId,
      name: 'Kontrakts- / ordrenummer',
      sortKey: 'referenceno1',
      filterKey: 'referenceno1',
      editable: true,
    },
    {
      id: ProjectOptColumn.CustomId,
      name: 'Internnummer',
      sortKey: 'referenceno2',
      filterKey: 'referenceno2',
      startOpen: true,
      editable: !isIntegration,
    },
    {
      id: ProjectOptColumn.FacilityId,
      name: 'ID anlegg',
      sortKey: 'facilityid',
      filterKey: 'facilityid',
      editable: true,
    },
    {
      id: ProjectOptColumn.Position,
      name: 'Posisjon',
      category: 'Adresse',
      startOpen: true,
      filterKey: 'geometry',
      getFilterList: filterListCountMapper(countFn, ({ value, count }) => [
        value,
        { name: JSON.parse(value) ? 'Har posisjon' : 'Ingen verdi', count },
      ]),
      mapValues: (value) =>
        value ? (JSON.parse(String(value)) ? 'Har posisjon' : 'Ingen verdi') : '',
      filterListOnly: true,
      isHidden: ({ opt }) => opt.type === OptType.Map,
      width: 8,
    },
    {
      id: ProjectOptColumn.Address,
      name: 'Adresse',
      category: 'Adresse',
      filterKey: 'address',
      sortKey: 'address',
      startOpen: true,
      editable: true,
    },

    {
      id: ProjectOptColumn.PriorityId,
      name: 'Prioritet',
      sortKey: 'priorityId',
      filterKey: 'priorityIds',
      filterListOnly: true,
      filterMultiple: true,
      editable: true,
      getFilterList: filterListCountMapper(countFn, ({ value, count }, [priorities]) => {
        return value === '0'
          ? [-100, { name: 'Ingen verdi', count }]
          : [
              Number(value),
              {
                name: priorities.get(Number(value))?.name ?? value,
                count,
                sortIndex: Number(value),
              },
            ];
      }),
      getOptions: ([priorities]) => new Map([[null, { name: 'Ingen verdi' }], ...priorities]),
      mapValues: (value, [priorities]) =>
        value === -100 ? 'Ingen verdi' : priorities.get(Number(value))?.name || value,
      dependencies: [() => adminStore.loadProjectPriorities()],
    },
    {
      id: ProjectOptColumn.DueDate,
      name: 'Frist',
      sortKey: 'duedate',
      mapValues: (value) => date(value) ?? '',
      editable: true,
      editType: 'date',
    },
    {
      id: ProjectOptColumn.RequestComment,
      name: 'Gjelder',
      category: 'Henvendelse',
    },
    {
      id: ProjectOptColumn.RequestDescription,
      name: 'Kommentar',
      category: 'Henvendelse',
    },
    {
      id: ProjectOptColumn.RequestPriorityId,
      name: 'Oppdragsgivers prioritet',
      category: 'Henvendelse',
      sortKey: 'requestpriorityid',
      filterKey: 'requestpriorityids',
      filterListOnly: true,
      filterMultiple: true,
      mapValues: (val) => priorityOptions.get(val),
      getFilterList: filterListCountMapper(countFn, ({ value, count }) => {
        return value === '0'
          ? [-100, { name: 'Ingen verdi', count }]
          : [
              Number(value),
              {
                name: priorityOptions.get(Number(value)) ?? value,
                count,
                sortIndex: Number(value),
              },
            ];
      }),
    },
    {
      id: ProjectOptColumn.RequestDueDate,
      name: 'Oppdragsgivers frist',
      category: 'Henvendelse',
      sortKey: 'requestduedate',
      mapValues: (value) => date(value) ?? '',
    },

    {
      id: ProjectOptColumn.CreatedAt,
      name: 'Opprettet',
      sortKey: 'createdat',
      mapValues: (value) => date(value) ?? '',
      width: 8,
    },
    {
      id: ProjectOptColumn.UpdatedAt,
      name: 'Sist oppdatert',
      sortKey: 'updatedat',
      mapValues: (value) => date(value) ?? '',
      startOpen: true,
      width: 8,
    },

    ...[...types.values()]
      .map((t) =>
        [...t.typeProperties.values()]
          .filter((p) => !p.hideInTable)
          .map((p) => {
            // props that are hoisted to "annet" NB: may be duplicate - see project-list2.ts
            const isRessurs = p.showInTableHead;

            return {
              id: p.id,
              name: p.shortName || p.name,
              category: isRessurs ? 'Annet' : t.name,
              filterKey: p.id,
              editable: true,
              mapValues: (value) => {
                if (value === '#EMPTY') return 'Ingen verdi';
                if (p.dataType === 'datetime') return date(value);
                return value;
              },
              isHidden: ({ opt }) => {
                return (
                  !getFilterValue(opt.filters, ProjectOptColumn.TypeId).includes(t.id) && !isRessurs
                );
              },
              ...(p.valueListGuid == null
                ? {}
                : {
                    getFilterList: filterListCountMapper(
                      countIdFn,
                      ({ value, count }, [valuelist]) => {
                        const item =
                          valuelist.get(value) ??
                          [...valuelist.values()].find(({ name }) => name === value);
                        return value == null
                          ? ['#EMPTY', { name: 'Ingen verdi', count }]
                          : [
                              value,
                              {
                                name: value,
                                count,
                                sortIndex: item?.sortIndex,
                              },
                            ];
                      },
                    ),
                    filterListOnly: p.type === 'select',
                    filterMultiple: true,
                    getOptions: ([valuelist]: any[]) =>
                      new Map([...valuelist].map(([id, { name }]) => [id, { name }])),
                    dependencies: [
                      // Remapping valuelists to use name as id because the count endpoint doesn't count based on id.
                      async () =>
                        new Map(
                          [
                            ...(
                              await loadValuelistByGuid({ valuelistGuid: p.valueListGuid })
                            ).valuelistItems.values(),
                          ].map((item) => [item.name, item]),
                        ),
                    ],
                  }),
            };
          }),
      )
      .flat(),
  ];

  // remove duplicate cols when placed in same category
  columns = columns.reduce((acc, cv) => {
    if (!acc.some((i) => i.id === cv.id && i.category === cv.category)) {
      acc.push(cv);
    }
    return acc;
  }, []);

  //remove hidden dynamic properties
  columns = columns.filter((cv) => {
    if (!selectedTypeId) return true;

    const isHidden = tenantProjectProperties.value
      .get(cv.id)
      ?.hiddenInProjectTypes.includes(selectedTypeId);

    return !isHidden;
  });

  switch (context) {
    case 'request':
      return columns;
    case 'partner':
      return columns.filter(({ id }) => partnerColumns.includes(id));
    case 'kommunepartner':
      return columns.filter(({ id }) => kpColumns.includes(id));
    default:
      return columns.filter((c) => c.category !== 'Henvendelse');
  }
}

export function loadProjectPage2({
  moduleId,
  opt,
  columns,
  incPropIds = [],
  incLabelIds = [],
  ids = [],
  signal = null,
  shared = false,
  useDepartments = true,
  useRequests = false,
}: LoadProjectPageOptions): Promise<{ count: number; entries: Array<Project2> }> {
  const query = createProjectQueryParams({
    opt,
    columns,
    moduleId,
    shared,
    incPropIds,
    incLabelIds,
    ids,
    useDepartments,
    useRequests,
  });

  return api.get(`/v2/projects?${query.toString()}`, { signal }).then(({ data }) => {
    return {
      entries: data.map((project) => mapProject(project)),
      count: data[0]?.TotalRows || 0,
    };
  });
}

export function downloadExport2({
  moduleId,
  opt,
  columns,
  shared = false,
  incPropIds = [],
  incLabelIds = [],
  ids = [],
  useDepartments = true,
}: LoadProjectPageOptions) {
  const query = createProjectQueryParams({
    opt,
    columns,
    moduleId,
    shared,
    incPropIds,
    incLabelIds,
    ids,
    useDepartments,
  });

  return api
    .post(
      `/v2/projects/export?${query.toString()}`,
      {},
      {
        responseType: 'blob',
      },
    )
    .then((result) => {
      const anchor = document.createElement('a');
      const date = localISODate(new Date()).slice(0, 16).replace(':', '-').replace('T', '_');
      anchor.href = URL.createObjectURL(result.data);
      anchor.download = `excel_eksport_${date}.xlsx`;

      anchor.click();
    });
}

export function updateProject(projectId: number, form: Project2) {
  return api
    .put(`/v1/projects/${projectId}`, {
      Name: form.name,
      Installer: form.installer,
      Customer: form.customer,
      ContactCompanyId: form.contactCompanyId,
      ContactId: form.contactId,
      Description: form.description,
      Municipality: form.municipality,
      Address: form.address,
      DueDate: form.dueDate ? setTime(form.dueDate) : null,
      Longitude: form.longitude,
      Latitude: form.latitude,
      ReferenceNo1: form.ref1,
      ReferenceNo2: form.customId,
      CaseworkerId: form.caseworkerId,
      FacilityId: form.facilityId,
      Gridowner: null,
      GridownerId: null,
      GridownerProjectName: form.gridownerProjectName,
      GridownerCaseworker: null,
      GridownerCaseworkerPhone: null,
      GridownerCaseworkerEmail: null,
      GridownerCaseworkerRefId: null,
      GridownerCaseworkerUserId: null,
      DepartmentId: form.departmentId,
      FolderId: form.folderId,
      OverviewListEnabled: form.overviewListEnabled,
      ColorId: form.colorId,
      PriorityId: form.priorityId,
    })
    .catch(genericApiErrorHandler);
}
